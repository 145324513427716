import React, { useContext } from 'react'
import { Button, Dropdown } from 'antd'
import { DefaultDropdownMenu } from 'components/Menu'
import { StatusTextWithColor, TextSm } from 'components/Typography'
import styled, { ThemeContext } from 'styled-components'
import { DarkTheme, LightTheme, PropsTheme } from 'theme'

import { CaretDownOutlined, CaretRightOutlined, ReloadOutlined } from '@ant-design/icons'

const { Item } = DefaultDropdownMenu

interface IMainButton extends PropsTheme {
  $minWidth?: number | string
  $width?: number | string
  $height?: number | string
  $fontSize?: number | string
  $padding?: number | string
  $colorType?: 'main' | 'secondary' | 'danger' | 'success' | 'info' | 'default' | 'lightWarning' | 'dark'
}

export const MainButton: any = styled(Button).attrs((props: IMainButton) => {
  return {
    ...props,
    $width: props?.$width,
    $height: props?.$height || 40,
    $fontSize: props?.$fontSize || props.theme.fontSizes.sm,
    $colorType: props?.$colorType || 'main'
  }
})`
  && {
    height: ${(props) => `${props.$height}px`};
    padding: 0px 24px;
    border-radius: 360px;
    text-align: center;
    font-size: ${(props) => props.$fontSize};
    background: ${(props) => props.theme.colors[props?.$colorType]};
    border: none;
    color: ${(props) => props.theme.colors.white};
    box-shadow: ${(props) => (`${props.$height}` < '40' ? '0px 2px 8px rgba(0, 0, 0, 0.08)' : 'unset')};
    display: flex;
    align-items: center;
    justify-content: center;

    :hover,
    :active,
    :focus {
      opacity: 0.8;
    }

    :disabled {
      opacity: 0.5;
    }

    svg {
      width: 14px;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

interface IOutlinedButton extends PropsTheme {
  $minWidth?: number | string
  $width?: number | string
  $height?: number | string
  $fontSize?: number | string
  $padding?: number | string
  $colorType?: 'main' | 'secondary' | 'danger' | 'success' | 'info' | 'default' | 'warning' | 'medium' | 'cheerful'
}

export const OutlinedButton: any = styled(Button).attrs((props: IOutlinedButton) => {
  return {
    ...props,
    $width: props?.$width,
    $height: props?.$height || 40,
    $fontSize: props?.$fontSize || props.theme.fontSizes.sm,
    $colorType: props?.$colorType || 'secondary'
  }
})`
  && {
    height: ${(props) => `${props.$height}px`};
    padding: ${(props) => (props.$padding ? `${props.$padding}` : '0px 24px')};
    text-align: center;
    font-size: ${(props) => props.$fontSize};
    border-radius: 360px;
    color: ${(props) => props.theme.colors[props?.$colorType]};
    border: ${(props) => `1px solid ${props.theme.colors[props?.$colorType]}`};
    box-shadow: ${(props) => (`${props.$height}` < '40' ? '0px 2px 8px rgba(0, 0, 0, 0.08)' : 'unset')};
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 14px;
      align-items: center;
    }

    :hover,
    :active,
    :focus {
      opacity: 0.8;
    }

    :disabled {
      opacity: 0.5;
    }
  }
`

export const WorkspaceButton = styled(MainButton)`
  &&& {
    width: 136px;
    height: 40px;
  }
`

// use in status color in button
export const StatusButton: any = styled(Button).attrs((props: IMainButton) => {
  return {
    ...props,
    $width: props?.$width,
    $height: props?.$height || 40,
    $fontSize: props?.$fontSize || props.theme.fontSizes.sm,
    $colorType: props?.$colorType || 'dark'
  }
})`
  && {
    height: ${(props) => `${props.$height}px`};
    padding: 0px 24px;
    text-align: center;
    font-size: ${(props) => props.$fontSize};
    border-radius: 360px;
    color: ${(props) => props.theme.colors[props?.$colorType]};
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      opacity: 0.8;
    }

    :disabled {
      opacity: 0.5;
    }
  }
`

// default white button
export const DefaultButton = styled(MainButton)`
  &&& {
    color: ${(props: PropsTheme) => props.theme.fontColor.dark};
    background-color: ${(props: PropsTheme) => props.theme.colors.white};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  }

  svg {
    height: 14px;
    align-items: center;
  }
`

// red button
export const DangerButton = styled(OutlinedButton)`
  &&& {
    color: ${(props: PropsTheme) => props.theme.fontColor.danger};
    background-color: ${(props: PropsTheme) => props.theme.colors.white};
    border: ${(props: PropsTheme) => `1px solid ${props.theme.fontColor.danger}`};
  }
  svg {
    width: 14px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

//solid button shadow
export const ButtonWithShadow = styled(OutlinedButton)`
  &&& {
    padding: 12px 24px;
    color: ${(props: PropsTheme) => props.theme.fontColor.dark};
    border: unset;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  }
`

//use in the modal footer
export const ConfirmButton = styled(MainButton)`
  &&& {
    width: ${(props) => props.width || '88px'};
    height: ${(props) => props.height || '36px'};
  }
`

//use in the modal footer
export const CancelButtonWithShadow = styled(OutlinedButton)`
  &&& {
    width: ${(props) => props.width || '88px'};
    height: ${(props) => props.height || '36px'};
    color: ${(props: PropsTheme) => props.theme.fontColor.light};
    border: unset;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  }
`

//use in the modal footer
export const CancelButton = styled(OutlinedButton)`
  &&& {
    width: ${(props) => props.width || '88px'};
    height: ${(props) => props.height || '36px'};
    color: ${(props: PropsTheme) => props.theme.fontColor.light};
    border: ${(props: PropsTheme) => `1px solid ${props.theme.colors.darkGrey}`};
  }
`

//use in form
export const AddButton = styled(OutlinedButton)`
  &&& {
    height: 36px;
    color: ${(props: PropsTheme) => props.theme.colors.main};
    border: ${(props: PropsTheme) => `1px solid ${props.theme.colors.main}`};
  }
`

export const PaymentButton = styled(OutlinedButton)`
  &&& {
    width: 72px;
    height: 32px;
    border-radius: 8px;
  }
`

export const DefaultDropdownButton: any = styled(Dropdown.Button).attrs((props) => {
  return {
    icon: props?.icon || <CaretDownOutlined style={{ fontSize: props.theme.fontSizes.smMinus }} />
  }
})`
  button {
    height: 40px;
    background: ${(props: PropsTheme) => props.theme.colors.white};
    color: ${(props: PropsTheme) => props.theme.fontColor.dark};
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
    border: none;
    outline: none;
    /* border-top-left-radius: 360px !important;
    border-bottom-left-radius: 360px !important; */

    :hover {
      color: ${(props: PropsTheme) => props.theme.colors.main};
      background: opacity 0.8;
    }

    :active {
      background: ${(props: PropsTheme) => props.theme.buttonColor.primaryFontColor};
      color: ${(props: PropsTheme) => props.theme.colors.main};
    }
    :disabled {
      opacity: 0.5;
    }
  }

  .ant-btn:first-child {
    padding: 0px 24px;
    border-top-left-radius: 360px !important;
    border-bottom-left-radius: 360px !important;
  }

  button:first-child:after {
    content: '';
    position: absolute;
    top: 25%;
    bottom: 25%;
    right: 1%;
    display: block;
    border-right: ${(props: PropsTheme) => `1px solid ${props.theme.colors.mediumGrey}`};
  }

  button:last-child {
    color: ${(props: PropsTheme) => props.theme.colors.darkGrey};
    border-top-right-radius: 360px !important;
    border-bottom-right-radius: 360px !important;

    span {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
    }

    :hover,
    :focus {
      color: ${(props: PropsTheme) => props.theme.colors.main};
    }

    :active {
      opacity: 0.8;
    }
  }
`

export const IconButton = styled(MainButton)`
  &&& {
    width: 40px !important;
    color: ${(props: PropsTheme) => props.theme.fontColor.medium};
    background: ${(props: PropsTheme) => props.theme.colors.white};
    border: none !important;
    padding: 0px !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);

    svg {
      width: 14px;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 24px;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    :hover,
    :focus {
      opacity: 0.8;
      color: ${(props: PropsTheme) => props.theme.colors.secondary};
    }
  }
`

export const RightButton = styled(MainButton)`
  &&& {
    width: 48px !important;
    height: 48px;
    color: ${(props: PropsTheme) => props.theme.fontColor.medium};
    background-color: ${(props: PropsTheme) => props.theme.colors.white};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
    padding: 0 !important;
    border-radius: 0px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    svg {
      width: 20px;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    :hover,
    :focus {
      opacity: 0.8;
      color: ${(props: PropsTheme) => props.theme.colors.secondary};
    }
  }
`

export const NavButton = styled(MainButton)`
  &&& {
    border: none !important;
    padding: 0px !important;

    :hover,
    :focus {
      opacity: 0.8;
      color: ${(props: PropsTheme) => props.theme.colors.main};
    }
  }
`

export const CopyButton: any = styled(Button)`
  && {
    font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
    color: ${(props: PropsTheme) => props.theme.colors.main};
    opacity: 0.6;
    width: auto;
    height: 14;
    padding: 0;
  }
`

export const ButtonIconDelete: any = styled(Button)`
  color: ${(props: PropsTheme) => props.theme.colors.medium};

  :hover,
  :focus,
  :active {
    color: ${(props: PropsTheme) => props.theme.colors.danger};
  }

  :disabled:hover {
    color: ${(props: PropsTheme) => props.theme.colors.medium};
  }
`

export const BorderlessButton: any = styled(Button)`
  border-radius: 15rem;
  outline: none;
  border: none;
`

export const ButtonLink: any = styled(Button)`
  && {
    font-size: ${(props: PropsTheme) => props.theme.fontSizes?.sm};
    color: ${(props: PropsTheme) => props.theme.colors?.main};
    height: auto;
    padding: 0;
  }

  :hover,
  :focus,
  :active {
    opacity: 0.8;
  }

  :disabled {
    opacity: 0.5;
  }
`

export const FilterButton: any = styled(Button)`
  &&& {
    width: auto !important;
    color: ${(props: PropsTheme) => props.theme.fontColor.main};
    background: ${(props: PropsTheme) => props.theme.colors.white};
    border: none !important;
    border-radius: 8px;
    padding: 0px 8px !important;

    span {
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    :hover,
    :focus {
      width: auto !important;
      color: ${(props: PropsTheme) => props.theme.fontColor.main};
      background: ${(props: PropsTheme) => props.theme.colors.white};
      border: none !important;
      border-radius: 8px;
      padding: 0px 8px !important;
      background-color: ${(props: PropsTheme) => props.theme.colors.hover};
    }
  }
`

export const LoadMoreButton: any = styled(Button)`
  display: flex;
  background: #fff;
  width: 100%;
  justify-content: center;
  border-radius: 0;
  border: none;
  font-size: 1rem;
  color: #00aaff;
  letter-spacing: 0;
  height: 2.625rem;
`

export const PaginationButton: any = styled(Button)`
  width: 2rem;
  height: 2rem;
`

export const ActionButton: any = styled(Button)`
  color: ${(props: PropsTheme) => props.theme.colors.medium};
  width: 16px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface IMultipleButton extends PropsTheme {
  width?: number
  height?: number
}

export const MultipleButton = styled.div.attrs((props: IMultipleButton) => {
  return {
    width: props?.width || 'auto',
    height: props?.height || '40'
  }
})`
  display: grid;
  grid-template-columns: 0.8fr 0.2fr;

  button {
    width: ${(props) => props?.width};
    height: ${(props) => props?.height};
  }

  button:first-child {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
    opacity: 1 !important;

    :hover,
    :focus {
      color: ${(props: PropsTheme) => props.theme.colors.secondary};
    }

    :after {
      content: '';
      border-right: 1px solid #eaeaea;
      position: absolute;
      right: 0;
      top: 50%;
      height: 50%;
      transform: translateY(-50%);
    }
  }

  button:last-child {
    padding: 0 8px;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    span {
      font-size: 12px;
      color: ${(props: PropsTheme) => props.theme.colors.medium};
    }
  }
`

//Refactored version here.
export const WrapperBehindLineButton = styled.div`
  display: grid;
  justify-content: center;
  :before {
    content: '';
    width: 100%;
    height: 0.5px;
    position: absolute;
    background: rgba(0, 0, 0, 0.06);
    top: 50%;
    transform: translateY(-50%);
  }
`

interface IWrapperChangeStateButton {
  $width: number
  $height: number
}

const WrapperChangeStateButton = styled.div.attrs((props: IWrapperChangeStateButton) => {
  return {
    $width: props?.$width || 'auto',
    $height: props?.$height || '40'
  }
})`
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;

  button {
    width: ${(props) => props?.$width};
    height: ${(props) => props?.$height};
  }
  button:first-child {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
    opacity: 1 !important;

    :hover,
    :focus {
      color: ${(props: PropsTheme) => props.theme.colors.secondary};
    }

    :after {
      content: '';
      border-right: 1px solid #eaeaea;
      position: absolute;
      right: 0;
      top: 50%;
      height: 50%;
      transform: translateY(-50%);
    }
  }

  button:last-child {
    padding: 0 8px;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    span {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  /* button:first-child {
    font-size: 12px;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    :after {
      content: '';
      border-right: 1px solid #dddddd;
      position: absolute;
      right: 0;
      top: 50%;
      height: 50%;
      transform: translateY(-50%);
    }
  }
  button:last-child {
    padding: 0 8px;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    span {
      font-size: 8px;
    }
  } */
`

export interface IChangeStateButton {
  data: { states: { [key: string]: string }; label: { [key: string]: string }; colors: string[] }
  currentState: string
  onChange: (state: string) => void
  $width?: number
  $height?: number
}

export const ChangeStateButton: React.FC<IChangeStateButton> = ({ data, currentState, onChange, $width, $height }) => {
  const themeContext = useContext(ThemeContext) as LightTheme | DarkTheme
  const currentStateIndex = Object.values(data?.states)?.indexOf(currentState)

  return (
    <WrapperChangeStateButton $width={$width} $height={$height}>
      <Dropdown
        trigger={['click']}
        menu={{
          onClick: ({ key: state }: any) => onChange(state),
          items: Object.entries(data?.states).map(([_, value], index) => {
            return {
              key: value,
              label: (
                <div key={value} style={{ display: 'grid', position: 'relative' }}>
                  <StatusTextWithColor statusColor={data?.colors?.[index]}>
                    <TextSm>{Object.values(data?.label)[index]}</TextSm>
                  </StatusTextWithColor>
                </div>
              )
            }
          })
        }}
        arrow
      >
        <StatusButton data-testid="dropdownMenuButton">
          <div style={{ display: 'grid', position: 'relative' }}>
            <StatusTextWithColor statusColor={data?.colors?.[currentStateIndex]}>
              {Object.values(data?.label)[currentStateIndex]}
            </StatusTextWithColor>
          </div>
        </StatusButton>
      </Dropdown>
      <StatusButton
        data-testid="nextStateButton"
        onClick={() => {
          if (currentStateIndex === Object.values(data?.states)?.length - 1) {
            onChange(Object.values(data?.states)[0])
          } else {
            onChange(Object.values(data?.states)[currentStateIndex + 1])
          }
        }}
        icon={<CaretRightOutlined />}
      />
    </WrapperChangeStateButton>
  )
}

interface PropsClearFilterButton {
  onClick: () => void
  label?: string
}
type ClearFilterWithHtml = PropsClearFilterButton & React.HTMLAttributes<HTMLDivElement>
export const ClearFilterButton: React.FC<ClearFilterWithHtml> = ({ onClick, label, ...props }) => {
  return (
    <ButtonLink {...props} onClick={onClick} type="link" icon={<ReloadOutlined style={{ color: '#FF595A' }} />}>
      <TextSm as="span" color="#FF595A">
        {label ? label : 'ล้างการตั้งค่า'}
      </TextSm>
    </ButtonLink>
  )
}

export const LinkLikeButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.colors.main};
  font-size: ${(props) => props.theme.fontSizes.smMinus};
  font-family: ${(props) => props.theme.fontFamily};
  cursor: pointer;
`

export interface BadgeButtonProps {
  status: 'info' | 'warning' | 'success' | 'danger'
  label: string
  count: number
  color?: string
  backgroundColor?: string
}
export const BadgeButton: React.FC<BadgeButtonProps> = ({ status, label, count, color, backgroundColor }) => {
  const themeContext = useContext(ThemeContext) as LightTheme | DarkTheme

  const bgColor =
    status === 'info'
      ? themeContext?.colors?.info
      : status === 'warning'
      ? themeContext?.colors?.warning
      : status === 'success'
      ? themeContext?.colors?.success
      : themeContext?.colors?.danger

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '10px',
        backgroundColor: backgroundColor || bgColor,
        color: color || themeContext?.colors?.white,
        padding: '4px 6px',
        gap: '6px'
      }}
    >
      {label}
      <div
        style={{
          backgroundColor: color || themeContext?.colors?.white,
          borderRadius: '12px',
          color: backgroundColor || bgColor,
          padding: '2px'
        }}
      >
        {count}
      </div>
    </div>
  )
}

export const HoldToConfirmButton = ({
  onClickSubmit = () => {},
  onHoldSubmit = () => {},
  holdDelay = 800,
  children,
  background = '#08CE87', // transition
  backgroundColor = '#2A62FE', // idle
  textColor = 'white',
  ...props
}) => {
  const [percentage, setPercentage] = React.useState(0)

  const startTime = React.useRef(null)
  const holdIntervalRef = React.useRef(null)

  React.useEffect(() => {
    return () => {
      holdIntervalRef.current && clearInterval(holdIntervalRef.current)
    }
  }, [])

  const startCounter = () => {
    if (holdIntervalRef.current) {
      return
    }
    startTime.current = Date.now()
    holdIntervalRef.current = setInterval(() => {
      if (startTime.current) {
        setPercentage(Math.floor(((Date.now() - startTime.current) / holdDelay) * 100))
        if (Date.now() - startTime.current > holdDelay) {
          stopCounter()
          onHoldSubmit()
        }
      }
    }, 100)
  }

  const stopCounter = () => {
    if (percentage && percentage <= 20) {
      onClickSubmit()
    }
    startTime.current = null
    setPercentage(0)
    if (holdIntervalRef.current) {
      clearInterval(holdIntervalRef.current)
      holdIntervalRef.current = null
    }
  }

  return (
    <MainButton
      {...props}
      onMouseDown={startCounter}
      onMouseUp={stopCounter}
      style={{
        background: `linear-gradient(${background}, ${background}) no-repeat top left`,
        backgroundColor,
        color: textColor,
        backgroundSize: `${percentage * 5}%`
      }}
    >
      {children} {/* {startTime.current && Date.now() - startTime.current || 0} / {holdDelay} = {percentage} */}
    </MainButton>
  )
}
